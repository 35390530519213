import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import { themeChange } from 'theme-change'
import checkAuth from "./app/auth";
import initializeApp from "./app/initApp";
import Landing from "./pages/Landing";
import Profile from "./pages/Profile";
import Layout from "./pages/Layout";

// Importing pages
const Dashboard = lazy(() => import("./pages/Dashboard"));
const LearnerModulePage = lazy(() => import("./pages/LearnerModulePage"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();


function App() {
  // TODO
  //   useEffect(() => {
  //     // 👆 daisy UI themes initialization
  //     themeChange(false)
  //   }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Landing />} />
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/program/:programId" element={<LearnerModulePage />} />
            <Route
              path="*"
              element={
                <Navigate to={token ? "/dashboard" : "/login"} replace />
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../app/api";
export const fetchUserData = createAsyncThunk(
  "program/fetchAllProgramData",
  async () => {
    const response = await axios.post(
      `${API_URL}learnerprograms`,
      {}
    );

    return response.data.programs;
  }
);

import { createSlice } from "@reduxjs/toolkit";
import { IProgramData } from "@/interfaces/dashboard.interface";
import { fetchUserData } from "./fetchUserData";
interface IProgramState {
  program: IProgramData[];
  filters: any;
  status: string;
}
const initialState: IProgramState = {
  program: [],
  filters: "",
  status: "",
};

export const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {},
  extraReducers: (userDataFetcher) => {
    userDataFetcher
      .addCase(fetchUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = "success";
        state.program = action.payload;
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const {} = programSlice.actions;

export default programSlice.reducer;
export { fetchUserData };

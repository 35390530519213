import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./providers/StoreProvider";
import SuspenseContent from "./containers/SuspenseContent";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css"; //For Primereact
import "primereact/resources/primereact.min.css"; //For Primereact icons
import "primereact/resources/themes/lara-light-indigo/theme.css"; //For Primereact icons
import "primeicons/primeicons.css"; //For Primereact icons
import "primeflex/primeflex.css"; //For Prime react grid
import { ToastProvider } from "./ToastProvider";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <Suspense fallback={<SuspenseContent />}>
      <PrimeReactProvider>
        <StoreProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </StoreProvider>
      </PrimeReactProvider>
    </Suspense>
  );
} else {
  console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

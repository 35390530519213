import React from "react";
import Login from "./programs/Login";
import { Menubar } from "primereact/menubar";
import { Image } from "primereact/image";
import logo from "../assets/images/logo.png";
import background from "../assets/images/bg.jpg";

export default function Landing() {
  const logoTemplate = () => <Image src={logo} alt="Image" width="250" />;

  const items = [{ template: logoTemplate }];

  return (
    <div
      className="bg-center bg-cover flex flex-col h-auto min-h-screen w-auto gap-4"
      style={{
        backgroundImage: `url('${background}')`,
      }}
    >
      {/* Menubar */}
      <Menubar
        model={items}
        className="shadow-md bg-white flex justify-content-start"
      />

      {/* Main Content */}
      <div className="flex flex-row justify-content-around items-start">
        {/* Left Content */}
        <div className="max-w-lg">
          <h1 className="text-4xl font-bold">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
            laborum veniam, culpa provident, magni, fugit quidem et iusto
            doloremque voluptates maiores quaerat similique tenetur. Aut eius
            magni distinctio delectus ad.
          </h1>
          <p className="text-lg text-gray-700">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
            placeat molestias, id iure eius possimus quas sint impedit tempore
            tempora magnam blanditiis doloremque aliquam accusamus odio earum
            dolor aut incidunt.
          </p>
        </div>

        {/* Right Content */}
        <div className="bg-white p-6 rounded-lg shadow-7 w-30rem">
          <Login />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Outlet } from "react-router-dom";
import background from "../assets/images/bg.jpg";
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";

export default function Layout() {
  return (
    <div
      className="bg-center bg-cover flex flex-col h-auto min-h-screen w-auto gap-4"
      style={{
        backgroundImage: `url('${background}')`,
      }}
    >
      <Navbar />
      {/* Main Content */}
      <div className="flex flex-row justify-content-around items-start p-2">
        <SideBar />
        <div className="flex flex-grow-1 flex-row justify-content-around pb-2">
        <Outlet />
        </div>
      </div>
    </div>
  );
}

import React, { useRef } from "react";
import { RootState } from "@/lib/store";
import { useDispatch, useSelector } from "react-redux";
import { Menubar } from "primereact/menubar";
import logo from "../assets/images/logo.png";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
// import TelegramButton from "@/pages/programs/TelegramButton";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { logout } from "../features/common/userSlice";

export default function Navbar() {
  //   const logoTemplate = () => <Image src={logo} alt="Image" width="250" />;
  const { user } = useSelector((state: RootState) => state.user);
  const menuRight = useRef<Menu>(null);
  const navigate = useNavigate();
  const id=user?._id || "";
  const dispatch=useDispatch();
  const handleLogoutClick = () => {
    // Perform any logout logic here if needed
    // console.log("Logout id:",id);
    localStorage.removeItem('persist:root');
    dispatch(logout({user:{_id:user._id}}));
    navigate("/login");
  };

  const items = [
    { label: "Profile", icon: "pi pi-user", url: "/profile" },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: () => {
        void handleLogoutClick();
      },
    },
  ];

  const start = (
    <img
      alt="logo"
      style={{ height: "50px" }}
      src={logo}
      className="mr-2"
    ></img>
  );
  const end = (
    <div className="flex align-items-center gap-2">
      {/* <TelegramButton /> */}
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
      />

      <Button
        label={user?.learner_name || "Guest"}
        className="w-full p-link flex align-items-center gap-2 p-2 text-color hover:surface-200 border-noround"
        onClick={(event) => menuRight?.current?.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
      >
        {
          <Avatar
            image={
              user?.profile_pic ||
              "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
            }
            className="mr-2"
            shape="circle"
          />
        }
      </Button>
    </div>
  );

  return <Menubar start={start} end={end} className="shadow-2"/>;
}
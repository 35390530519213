import React from "react";
import { useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { RootState } from "@/lib/store";

const ProfilePage = () => {
  const { user } = useSelector((state: RootState) => state.user);
  // const [editMode, setEditMode] = useState(false);

  // const handleEditToggle = () => {
  //   setEditMode(!editMode);
  // };

  return (
    <div className="card flex flex-col gap-4 max-w-4xl mb-8 p-4 rounded-3xl shadow-2">
      <h2 className="text-2xl font-semibold">Profile</h2>
      <form className="grid grid-cols-2 gap-4">
        {/*name*/}
        <div className="ml-1 p-2">
          <label className="block text-lg font-bold mb-3 text-gray-700">
            Learner Name
          </label>
          <InputText
            type="text"
            value={user?.learner_name || ""}
            readOnly
            className="w-[350px] border p-2 border-gray-400 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* Email ID */}
        <div className="p-2">
          <label className="block text-lg font-bold mb-3 text-gray-700">
            Email ID <span className="text-red-500">*</span>
          </label>
          <InputText
            type="email"
            value={user?.email_id || ""}
            readOnly
            className="w-[350px] border border-gray-400 rounded-xl shadow-xl p-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* Gender */}
        <div className="ml-1 p-2">
          <label className="block text-lg font-bold mb-3 text-gray-700">
            Gender
          </label>
          <InputText
            type="text"
            value={user?.gender || ""}
            readOnly
            className="w-[350px] border p-2 border-gray-400 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* Status */}
        <div className="ml-2 p-2">
          <label className="block text-lg font-bold mb-3 text-gray-700">
            Status
          </label>
          <InputText
            type="text"
            value={user?.status ? "Active" : "Inactive"}
            readOnly
            className="w-[350px] border p-2 border-gray-400 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* Valid From */}
        <div className="ml-0 mt-2 p-3">
          <label className="block text-lg font-bold mb-3 text-gray-700">
            Valid From <span className="text-red-500">*</span>
          </label>
          <InputText
            type="date"
            value={new Date(user?.valid_from).toISOString().split("T")[0]}
            readOnly
            className="w-[350px]  rounded-xl border p-2 border-gray-400 shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* Valid To */}
        <div className="ml-0 mt-2 p-3">
          <label className="block text-lg mb-3 font-bold text-gray-700">
            Valid To <span className="text-red-500">*</span>
          </label>
          <InputText
            type="date"
            value={new Date(user?.valid_to).toISOString().split("T")[0]}
            readOnly
            className="w-[350px] rounded-xl border p-2 border-gray-400 shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* college-reg-no */}
        <div className="mt-1 ml-3">
          <label className="block text-lg font-bold mb-2 text-gray-700">
            College_reg_no <span className="text-red-500">*</span>
          </label>
          <InputText
            type="text"
            value={user?.college_reg_no || ""}
            readOnly
            className="w-[350px] border border-gray-400 p-2 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* DEPT */}
        <div className="mt-0 ml-5">
          <label className="block text-lg font-bold text-gray-700">
            Department <span className="text-red-500">*</span>
          </label>
          <InputText
            type="text"
            value={user?.department || ""}
            readOnly
            className="w-[350px] border p-2 mt-3 border-gray-400 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* Mobile Number */}
        <div className="mt-0 p-3">
          <label className="block text-lg font-bold mb-2 text-gray-700">
            Mobile No
            {/* <span className="text-red-500">*</span> */}
          </label>
          <InputText
            type="tel"
            value={user?.mobile_no || ""}
            readOnly
            className="w-[350px] border border-gray-400 p-2 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {/* D.O.B */}
        <div className="mt-2 ml-3 mr-3">
          <label className="block text-lg font-bold mb-3 text-gray-700">
            Date of Birth
            {/* <span className="text-red-500">*</span> */}
          </label>
          <InputText
            type="date"
            value={
              user?.date_of_birth
                ? new Date(user?.date_of_birth).toISOString().split("T")[0]
                : undefined
            }
            readOnly
            className="w-[350px] border border-gray-400 p-2 rounded-xl shadow-xl focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </form>
      {/* Edit Button */}
      {/* <Button
            onClick={handleEditToggle}
            className="mt-5 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            {editMode ? "Save" : "Edit"}
          </Button> */}
    </div>
  );
};

export default ProfilePage;
